import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getPagePath } from '../../utils/appRoutes';
import {
  ARD_CUSTOMER_SERVICE_DELIVERY,
  ARD_CUSTOMER_SERVICE_ORDER_AND_PAY,
  ARD_CUSTOMER_SERVICE_RETURN_AND_CANCEL,
} from '../../utils/appRoutes.definitions';

import FooterSection from '../FooterSection';

const CustomerServiceLetter = () => {
  const config = useSelector((state) => state.config);
  const { formatMessage } = useIntl();

  const links = useMemo(
    () => [
      {
        title: formatMessage({ id: 'footer.customerService.orderAndPay', defaultMessage: 'Order and pay' }),
        slug: getPagePath(ARD_CUSTOMER_SERVICE_ORDER_AND_PAY),
      },
      {
        title: formatMessage({
          id: 'footer.customerService.shippingAndDelivery',
          defaultMessage: 'Shipping and delivery',
        }),
        slug: getPagePath(ARD_CUSTOMER_SERVICE_DELIVERY),
      },
      {
        title: formatMessage({
          id: 'footer.customerService.returnAndCancel',
          defaultMessage: 'Returns and cancellations',
        }),
        slug: getPagePath(ARD_CUSTOMER_SERVICE_RETURN_AND_CANCEL),
      },
    ],
    [config]
  );

  return (
    <FooterSection
      links={links}
      title={formatMessage({ id: 'footer.customerService.title', defaultMessage: 'Customer service' })}
    />
  );
};

export default CustomerServiceLetter;

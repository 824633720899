/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import React, { useState, useMemo } from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import styles from './FooterSectionMarketplace.module.scss';
import { getPagePath } from '../../utils/appRoutes';
import {
  ARD_ALL_CATEGORIES,
  ARD_ALL_SHOPS,
  ARD_BUSINESS,
  ARD_COOKIES,
  ARD_PRIVACY,
  ARD_TERMS_AND_CONDITIONS,
} from '../../utils/appRoutes.definitions';
import CustomerServiceLetter from '../CustomerServiceLetter/CustomerServiceLetter';
import FooterSection from '../FooterSection';

const LoginModal = loadable(() => import('../LoginModal/LoginModal'));

const FooterSectionMarketplace = ({ isShort }) => {
  const config = useSelector((state) => state.config);
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  const overShopLinks = useMemo(() => {
    let links = [
      {
        title: formatMessage({ id: 'footer.marketplace', defaultMessage: 'The marketplace of the Netherlands' }),
        slug: '/',
      },
    ];
    if (!isShort)
      links = [
        ...links,
        {
          title: formatMessage({ id: 'footer.webshops', defaultMessage: 'Webshops' }),
          slug: getPagePath(ARD_ALL_SHOPS),
        },
        {
          title: formatMessage({ id: 'footer.allCategories', defaultMessage: 'All categories' }),
          slug: getPagePath(ARD_ALL_CATEGORIES),
        },
      ];
    return links;
  }, [isShort]);

  const partnerLinks = useMemo(() => {
    let links = [
      {
        title: formatMessage(
          { id: 'footer.partner.sell', defaultMessage: 'Sell via {webShopName}' },
          { webShopName: config.websiteName }
        ),
        slug: getPagePath(ARD_BUSINESS),
      },
    ];
    if (!isShort)
      links = [
        ...links,
        {
          title: formatMessage({ id: 'footer.partner.partnerships', defaultMessage: 'Partnerships' }),
          slug: '/',
        },
      ];
    return links;
  }, [isShort]);

  const informedLinks = useMemo(() => {
    let links = [
      {
        title: formatMessage({ id: 'footer.inform.newsLetter', defaultMessage: 'NewsLetter' }),
        slug: '/',
      },
    ];
    if (!isShort)
      links = [
        ...links,
        {
          title: formatMessage({ id: 'footer.inform.instagram', defaultMessage: 'Instagram' }),
          slug: '/',
        },
        {
          title: formatMessage({ id: 'footer.inform.youtube', defaultMessage: 'Youtube' }),
          slug: '/',
        },
        {
          title: formatMessage({ id: 'footer.inform.tiktok', defaultMessage: 'Tiktok' }),
          slug: '/',
        },
      ];
    return links;
  }, [isShort]);

  const informationLinks = useMemo(() => {
    let links = [
      {
        title: formatMessage({ id: 'footer.information.privacy', defaultMessage: 'Privacy' }),
        slug: getPagePath(ARD_PRIVACY),
      },
    ];
    if (!isShort)
      links = [
        {
          title: formatMessage({ id: 'footer.information.termsAndConditions', defaultMessage: 'Terms and conditions' }),
          slug: getPagePath(ARD_TERMS_AND_CONDITIONS),
        },
        ...links,
        {
          title: formatMessage({ id: 'footer.information.cookies', defaultMessage: 'Cookies' }),
          slug: getPagePath(ARD_COOKIES),
        },
      ];
    return links;
  }, [isShort]);

  return (
    <>
      <section className={styles.newsletterSection}>
        <div className="container">
          <div className="row">
            <div className={classnames(styles.left, 'col-3-l col-2-xl', { ['col-3-xl']: isShort })}>
              <img
                width={isShort ? '224px' : '150px'}
                src={config.theme.logos.main_logo_inverted || config.theme.logos.main_logo}
                alt="Webshop footer logo"
              />
              {!isShort && (
                <>
                  <p>{config.footer?.webshop?.line1}</p>
                  <p>{config.footer?.webshop?.line2}</p>
                </>
              )}
            </div>

            <div className={classnames('col-12-xs col-3-l col-2-xl')}>
              <FooterSection
                links={overShopLinks}
                title={
                  <FormattedHTMLMessage
                    id="footer.aboutFooter"
                    defaultMessage="about"
                    values={{ webShopName: config.websiteName, webShopLink: '/' }}
                  />
                }
              />
            </div>

            {!isShort && (
              <div className={classnames('col-12-xs col-3-l col-2-xl')}>
                <CustomerServiceLetter />
              </div>
            )}
            <div className="col-12-xs col-3-l col-2-xl">
              <FooterSection
                links={partnerLinks}
                title={formatMessage({ id: 'footer.partner.title', defaultMessage: 'Become a partner' })}
              />
            </div>
            <div className="col-12-xs col-3-l col-2-xl">
              <FooterSection
                links={informedLinks}
                title={formatMessage({ id: 'footer.inform.title', defaultMessage: 'Stay informed' })}
              />
            </div>
            <div className="col-12-xs col-3-l col-2-xl">
              <FooterSection
                links={informationLinks}
                title={formatMessage({ id: 'footer.information.title', defaultMessage: 'Information' })}
              />
            </div>
          </div>
        </div>
      </section>
      {isOpen && <LoginModal isOpen={isOpen} setOpen={setIsOpen} newsletter />}
    </>
  );
};

export default FooterSectionMarketplace;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './FooterSection.module.scss';
import { ReactComponent as ArrowDown } from '../../images/icons/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../images/icons/arrowUp.svg';
import classNames from 'classnames';

const FooterSection = ({ links, title }) => {
  const [open, setOpen] = useState(false);
  const triggerOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={styles.root}>
      <div className={styles.title} onClick={triggerOpen}>
        {title} <span className={styles.arrowIcon}> {open ? <ArrowUp /> : <ArrowDown />}</span>
      </div>
      <ul className={classNames({ [styles.hideLinkContainer]: !open })}>
        {links.map((item) => (
          <li key={item.title}>
            <Link to={item.slug}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterSection;
